import Partner1 from './assets/partner-1.png';
import Partner2 from './assets/partner-2.png';
import Partner3 from './assets/partner-3.png';
import Partner4 from './assets/partner-4.png';
import Partner5 from './assets/partner-5.png';
import Partner6 from './assets/partner-6.png';
import Partner7 from './assets/partner-7.png';
import Partner8 from './assets/partner-8.png';
import Partner9 from './assets/partner-9.png';
import Partner10 from './assets/partner-10.png';
import Partner11 from './assets/partner-11.png';
import Partner12 from './assets/partner-12.png';
import Partner13 from './assets/partner-13.svg';
import Partner14 from './assets/partner-14.png';
import Partner15 from './assets/partner-15.png';
import Partner16 from './assets/partner-16.png';
import Partner17 from './assets/partner-17.png';

export const partnersList1 = [
  {
    img: Partner15,
    name: 'Aptos',
    width: 254,
    height: 63,
  },
  {
    img: Partner16,
    name: 'Movementum',
    width: 314,
    height: 66,
  },
  { img: Partner10, name: 'Google cloud', width: 330, height: 84, containerWidth: 350 },
  {
    img: Partner14,
    name: 'Amnis',
    width: 270,
    height: 76,
  },
  { img: Partner3, name: 'MCM', width: 238, height: 75, containerWidth: 350 },
  { img: Partner1, name: 'Plume', width: 334, height: 80, containerWidth: 350 },
  { img: Partner8, name: 'Fragmetric', width: 349, height: 73, containerWidth: 350 },
  { img: Partner2, name: 'MxN Technology', width: 283, height: 132, containerWidth: 350 },
  { img: Partner17, name: 'Movebit', width: 304, height: 60 },

  { img: Partner9, name: 'Searchfi', width: 350, height: 150, containerWidth: 350 },
  { img: Partner5, name: 'MxN Commerce', width: 185, height: 150, containerWidth: 185 },
  { img: Partner12, name: 'ICP', width: 295, height: 52, containerWidth: 350 },
];

export const partnersList2 = [
  { img: Partner7, name: 'Polytrade', width: 350, height: 50, containerWidth: 350 },
  { img: Partner4, name: 'Pinewood capital', width: 267, height: 91, containerWidth: 350 },
  { img: Partner11, name: 'Xraders', width: 333, height: 121, containerWidth: 350 },
  { img: Partner6, name: 'The Freeport', width: 350, height: 30, containerWidth: 400 },
  { img: Partner13, name: 'Eclipse Labs', width: 364, height: 86, containerWidth: 350 },
];
