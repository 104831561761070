import { useEffect, useState } from 'react';

export const useImage = (imageSrc: string | undefined) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!imageSrc) {
      setLoaded(false);
      return;
    }
    const image = new Image();
    image.src = imageSrc;
    image.addEventListener('load', () => setLoaded(true));
  }, [imageSrc]);

  return { loaded };
};
