import { FC } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';

import { partnersList1, partnersList2 } from './Partners.mocked';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 64,
  fontWeight: FontWeights.fontWeightBlack,
  lineHeight: '66px',
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
    lineHeight: '36px',
  },
}));

const DEFAULT_ITEMS_PER_ROW_LG = 3;
const TOTAL_SECOND_PARTNERS_LIST = partnersList2.length;

// this cringe code was long before, i've just added some more
export const Partners: FC = () => {
  return (
    <Stack>
      <Title
        textAlign="center"
        mt={{ xs: '30px', md: '70px', lg: '105px' }}
        mb={{ xs: '30px', sm: '60px', md: '100px', lg: '134px' }}
      >
        Trusted partners worldwide
      </Title>

      <Box sx={{ maxWidth: '1700px', mx: 'auto' }}>
        <Grid container spacing={{ xs: 1, sm: 5, md: 8 }}>
          {partnersList1.map(({ img, name, height, width }) => {
            return (
              <Grid item key={name} xs={12} sm={6} md={4} lg={3}>
                <Stack
                  key={name}
                  height={{ xs: 75, md: 150 }}
                  maxWidth={{ xs: 205, md: 'unset' }}
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                >
                  <Box
                    component="img"
                    src={img}
                    sx={{
                      maxHeight: height,
                      maxWidth: width,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      pointerEvents: 'none',
                    }}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box sx={{ maxWidth: '1250px', mx: 'auto', mt: { sm: 5, md: 8 } }}>
        <Grid container spacing={{ xs: 1, sm: 5, md: 8 }}>
          {partnersList2.map(({ img, name, height, width }, index) => {
            const remainder = TOTAL_SECOND_PARTNERS_LIST % DEFAULT_ITEMS_PER_ROW_LG;
            const startLastRow =
              remainder === 0
                ? TOTAL_SECOND_PARTNERS_LIST - DEFAULT_ITEMS_PER_ROW_LG
                : TOTAL_SECOND_PARTNERS_LIST - remainder;

            const isLastRow = index >= startLastRow;

            const lgValue = isLastRow ? 6 : DEFAULT_ITEMS_PER_ROW_LG + 1;

            return (
              <Grid item key={name} xs={12} sm={6} md={6} lg={lgValue}>
                <Stack
                  height={{ xs: 75, md: 150 }}
                  width="100%"
                  maxWidth={{ xs: 205, md: '100%' }}
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                >
                  <Box
                    component="img"
                    src={img}
                    sx={{
                      maxHeight: height,
                      maxWidth: width,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      pointerEvents: 'none',
                    }}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
};
