import { FC } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';

import { Partners } from './components/Partners';
import { HotStyles, MostPopular, NotableNews, Preview, TitleBlock, TopCollectors, WhatsNew } from './components';

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  lineHeight: '60px',
  fontWeight: FontWeights.fontWeightBold,
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
    lineHeight: '29px',
  },
}));

export const Home: FC = () => {
  return (
    <Stack spacing={{ xs: 5, sm: 6 }}>
      <TitleBlock />
      <Preview />
      <WhatsNew />
      <HotStyles />
      <MostPopular />
      <NotableNews />
      <TopCollectors />
      <Partners />
    </Stack>
  );
};
